<template>
  <div
    v-if="loaded"
    :class="['Dashboard column', {
      'Dashboard--mobile': mobile.isMobile
    }]"
  >
    <div class="Dashboard__heading row row--align-center row--justify-between">
      <div class="row row--align-end row--width-auto">
        <label class="fs-22 fw-med def-lh">Dashboard</label>

        <template v-if="dashboard === 'client'">
          <!-- This link should only show up for employees -->
          <!-- Link to client's activity page (default redirect of /client) -->
          <router-link
            v-if="client && !userIsClient"
            class="fc-light fs-12 ml-12"
            :to="{ name: 'client', params: { id: id.toString() }}"
          >
            {{ client.shortened_name }}
          </router-link>
        </template>
      </div>

      <!-- CLIENT BALANCE LABEL -->
      <div
        v-if="dashboard === 'client' && clientBalance"
        class="row row--align-center row--justify-end row--width-auto"
      >
        <transition-group name="Transition__opacity-fade">
          <template v-if="clientBalance">
            <label
              class="fc-light fs-13 mr-6"
              key="label"
            >
              Balance:
            </label>
            <label
              :class="['Dashboard__balance-flag fs-16', {
                'Dashboard__balance-flag--bg-light': clientBalance === 0,
                'Dashboard__balance-flag--bg-green': clientBalance > 0,
                'Dashboard__balance-flag--bg-red': clientBalance < 0,
              }]"
              key="value"
            >
              {{
                Intl
                  .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(clientBalance / 100)
              }}
            </label>
          </template>
        </transition-group>
      </div>
    </div>
    <!-- NAVIGATION -->
    <div class="Dashboard__navigation row row--align-center">
      <router-link
        v-for="(route, index) in routes"
        :key="index"
        :id="`Testing__${route.text.replace(/ /g, '')}`"
        :to="route.to"
        active-class="Dashboard__navigation-item--active fc-white"
        class="Dashboard__navigation-item fc-mid fs-15 capitalize"
        tag="a"
      >
        {{ route.text }}
      </router-link>
    </div>

    <router-view
      @update-balance="getClientBalance"
      class="Dashboard__router-view width-100"
    />
  </div>
</template>

<script>
// Helpers
import {
  Balance,
  Client
} from '../../utils/api'

export default {
  name: 'Dashboard',

  props: {
    id: {
      type: [Number, String],
      required: false,
      default: null,
    },
    userIsClient: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  beforeRouteEnter (to, from, next) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.client) {
      if (!to.params.id) {
        next({ name: 'dashboard-client', params: { id: user.client.id.toString() } })
      }
      // Prevents going to Bobtail and other clients' dashboards
      if (to.params.id === user.client.id && to.name.includes('client')) {
        next()
      } else {
        next({ name: 'dashboard-client', params: { id: user.client.id.toString() } })
      }
    } else {
      next()
    }
  },

  async created () {
    // Regardless of current user being client/employee, if client dashboard, use client routes
    // If Bobtail dashboard, use employee routes
    // If a client user tries to manually go to an employee dashboard via the URL,
    // $beforeRouteEnter runs before created
    if (this.$route.name.includes('client')) {
      this.dashboard = 'client'
      this.routes = this.defaultClientRoutes

      // Need the client's shortened name for the link back to client profile
      try {
        this.client = (await Client.get(this.id)).data
      }
      catch (error) {
        // If user is not found, go to user-management and skip error reporting
        // Don't have to worry about this running for a client - they get auto-redirected
        if (error.response.status === 404) {
          this.$router.push({
            name: 'user-management',
          })
          this.requestInfo({ message: 'There isn\'t a client with that ID' })
          return
        }

        this.captureSentryEvent(
          'Dashboard "Get Client" in Created()',
          {
            clientId: this.id,
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client' })
      }
      // Get the client's balance (runs for a client dashboard)
      await this.getClientBalance()
    }

    if (this.$route.name.includes('employee')) {
      this.dashboard = 'bobtail'
      this.routes = this.defaultBobtailRoutes
    }

    this.loaded = true
  },

  data () {
    return {
      client: null, // Needed for link back to client profile
      clientBalance: null,
      dashboard: null,
      defaultBobtailRoutes: [
        {
          text: 'volume',
          to: { name: 'dashboard-employee-volume' },
        },
        {
          text: 'balances',
          to: { name: 'dashboard-employee-balances' },
        },
        {
          text: 'reports',
          to: { name: 'dashboard-employee-reports' },
        },
        {
          text: 'collections',
          to: { name: 'dashboard-employee-collections' },
        },
        {
          text: 'transfers',
          to: { name: 'dashboard-employee-transfers' },
        },
        {
          text: 'quickbooks',
          to: { name: 'dashboard-employee-quickbooks' },
        },
        {
          text: 'audit log',
          to: { name: 'dashboard-employee-audit-log' },
        },
      ],
      defaultClientRoutes: [
        {
          text: 'notifications',
          to: { name: 'dashboard-client-notifications', params: { id: this.id } },
        },
        {
          text: 'transfers',
          to: { name: 'dashboard-client-transfers', params: { id: this.id } },
        },
        {
          text: 'balance history',
          to: { name: 'dashboard-client-balance-history', params: { id: this.id } },
        },
        {
          text: 'bank accounts',
          to: { name: 'dashboard-client-banking', params: { id: this.id } },
        },
        // TODO: https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2103
        // {
        //   text: 'drivers',
        //   to: { name: 'dashboard-client-drivers', params: { id: this.id } },
        // },
      ],
      loaded: false,
      routes: null,
    }
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },
  },

  watch: {
    async id () {
      if (!this.client) {
        try {
          this.client = (await Client.get(this.id)).data
        }
        catch (error) {
          this.captureSentryEvent(
            'Dashboard "ID watcher"',
            {
              clientId: this.id,
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: 'There was an issue getting the new client' })
        }
      }
    },

    $route (newRoute) {
      // Required to prevent a client user from viewing employee pages by visting it via the URL,
      // then hitting the back arrow in their browser after their redirected to their payments page
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.client) {
        if (this.$route.params.id === user.client.id && this.$route.name.includes('client')) {
          return
        }
        this.$router.push({
          name: 'dashboard-client-notifications',
          params: {
            id: user.client.id
          }
        })
      }

      // When route changes, make sure the navigation is updated (doesn't include child routes)
      // i.e. If an employee goes from a client dashboard to the Bobtail, routes need updated
      // NOTE: Even though this information will be valid on a route change from employee to client dashboard
      // the above ID watcher WILL not have finished getting the new client yet, unless that client being loaded
      // has been loaded in the current user's active session. To fix this: we'll only render certain template
      // components if client and clientBalance exist
      if (newRoute.name.includes('client')) {
        this.getClientBalance()
        this.dashboard = 'client'
        this.routes = this.defaultClientRoutes
      }

      if (newRoute.name.includes('employee')) {
        this.clientBalance = null
        this.$store.commit('STORE_CLIENT_BALANCE', null)
        this.dashboard = 'bobtail'
        this.routes = this.defaultBobtailRoutes
      }
    }
  },

  methods: {
    async getClientBalance () {
      try {
        this.clientBalance = (await Balance.getClientTotalBalance(this.id)).data.total
        this.$store.commit('STORE_CLIENT_BALANCE', this.clientBalance)
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard "Get Client Balance"',
          {
            clientId: this.id,
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s balance' })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.Dashboard
  $block: &
  background-color: $white
  border: $border
  border-radius: $border-radius
  box-shadow: $shadow-b
  height: 100%
  position: relative
  width: 100%

  &__balance-flag
    border-radius: rem(15px)
    display: inline-block
    line-height: 1
    padding: rem(6px) rem(12px)

    &--bg-green
      background-color: rgba(#34AA44, 0.2)
      color: $success

    &--bg-red
      background-color: #FFEDE9
      color: $danger

    &--bg-light
      background-color: lighten(#A6A8AD, 28)
      color: $text-light

  &__heading
    padding: rem(40px) rem(30px)

  &__navigation
    background-color: rgba($background, .3)
    border-top: $border
    padding: rem(10px) rem(29px)

  &__navigation-item
    margin-right: rem(34px)

    &--active
      background-color: $branding
      border-radius: $border-radius
      padding: rem(2px) rem(10px)

    &:last-child
      margin-right: 0

  &__router-view
    padding: rem(36px) rem(30px) 2.5rem

  &--mobile

    #{$block}__heading
      display: none

    #{$block}__navigation
      display: none

    #{$block}__router-view
      min-height: 100vh
      padding: 0 1rem
</style>
