var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        {
          class: [
            "Dashboard column",
            {
              "Dashboard--mobile": _vm.mobile.isMobile
            }
          ]
        },
        [
          _c(
            "div",
            {
              staticClass:
                "Dashboard__heading row row--align-center row--justify-between"
            },
            [
              _c(
                "div",
                { staticClass: "row row--align-end row--width-auto" },
                [
                  _c("label", { staticClass: "fs-22 fw-med def-lh" }, [
                    _vm._v("Dashboard")
                  ]),
                  _vm.dashboard === "client"
                    ? [
                        _vm.client && !_vm.userIsClient
                          ? _c(
                              "router-link",
                              {
                                staticClass: "fc-light fs-12 ml-12",
                                attrs: {
                                  to: {
                                    name: "client",
                                    params: { id: _vm.id.toString() }
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.client.shortened_name) +
                                    "\n        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm.dashboard === "client" && _vm.clientBalance
                ? _c(
                    "div",
                    {
                      staticClass:
                        "row row--align-center row--justify-end row--width-auto"
                    },
                    [
                      _c(
                        "transition-group",
                        { attrs: { name: "Transition__opacity-fade" } },
                        [
                          _vm.clientBalance
                            ? [
                                _c(
                                  "label",
                                  {
                                    key: "label",
                                    staticClass: "fc-light fs-13 mr-6"
                                  },
                                  [_vm._v("\n            Balance:\n          ")]
                                ),
                                _c(
                                  "label",
                                  {
                                    key: "value",
                                    class: [
                                      "Dashboard__balance-flag fs-16",
                                      {
                                        "Dashboard__balance-flag--bg-light":
                                          _vm.clientBalance === 0,
                                        "Dashboard__balance-flag--bg-green":
                                          _vm.clientBalance > 0,
                                        "Dashboard__balance-flag--bg-red":
                                          _vm.clientBalance < 0
                                      }
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                          }).format(_vm.clientBalance / 100)
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _c(
            "div",
            { staticClass: "Dashboard__navigation row row--align-center" },
            _vm._l(_vm.routes, function(route, index) {
              return _c(
                "router-link",
                {
                  key: index,
                  staticClass:
                    "Dashboard__navigation-item fc-mid fs-15 capitalize",
                  attrs: {
                    id: "Testing__" + route.text.replace(/ /g, ""),
                    to: route.to,
                    "active-class":
                      "Dashboard__navigation-item--active fc-white",
                    tag: "a"
                  }
                },
                [_vm._v("\n      " + _vm._s(route.text) + "\n    ")]
              )
            }),
            1
          ),
          _c("router-view", {
            staticClass: "Dashboard__router-view width-100",
            on: { "update-balance": _vm.getClientBalance }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }